// Generated by Framer (c7eb1b9)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useLocaleInfo, useVariantState, withCSS, withFX } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
const ImageWithFX = withFX(Image);

const cycleOrder = ["ENfrI2Euc"];

const serializationHash = "framer-JwnsL"

const variantClassNames = {ENfrI2Euc: "framer-v-ra4w44"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const transformTemplate1 = (_, t) => `perspective(1200px) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, image, width, ...props}) => { return {...props, n75ENNN63: image ?? props.n75ENNN63 ?? {src: "https://framerusercontent.com/images/OWmqyHqUM5E6Uu1XmGANwfVq9Uc.jpg?scale-down-to=1024", srcSet: "https://framerusercontent.com/images/OWmqyHqUM5E6Uu1XmGANwfVq9Uc.jpg?scale-down-to=1024 819w, https://framerusercontent.com/images/OWmqyHqUM5E6Uu1XmGANwfVq9Uc.jpg 1200w"}} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;image?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, n75ENNN63, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "ENfrI2Euc", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-ra4w44", className, classNames)} data-framer-name={"Desktop"} initial={variant} layoutDependency={layoutDependency} layoutId={"ENfrI2Euc"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><motion.div className={"framer-m3zxw4"} data-framer-name={"Aspect Ratio 4x5"} layoutDependency={layoutDependency} layoutId={"kSMvIkwOk"}><ImageWithFX __framer__spring={{damping: 40, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 400, type: "spring"}} __framer__styleTransformEffectEnabled __framer__transformTargets={[{target: {opacity: 1, rotate: 0, rotateX: 0, rotateY: 0, scale: 1.25, x: 0, y: 0}}, {target: {opacity: 1, rotate: 0, rotateX: 0, rotateY: 0, scale: 1, x: 0, y: 0}}]} __framer__transformTrigger={"onInView"} __perspectiveFX={false} __smartComponentFX __targetOpacity={1} background={{alt: "", fit: "fill", intrinsicHeight: 750, intrinsicWidth: 600, pixelHeight: 1500, pixelWidth: 1200, sizes: "min(600px, 100vw)", ...toResponsiveImage(n75ENNN63)}} className={"framer-18ixzj0"} data-framer-name={"Image"} layoutDependency={layoutDependency} layoutId={"Mv_DZQM28"} transformTemplate={transformTemplate1}/></motion.div></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-JwnsL[data-border=\"true\"]::after, .framer-JwnsL [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-JwnsL.framer-qwt0hd, .framer-JwnsL .framer-qwt0hd { display: block; }", ".framer-JwnsL.framer-ra4w44 { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 600px; }", ".framer-JwnsL .framer-m3zxw4 { align-content: center; align-items: center; aspect-ratio: 0.8 / 1; display: flex; flex: none; flex-direction: column; flex-wrap: nowrap; gap: 0px; height: var(--framer-aspect-ratio-supported, 750px); justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 100%; }", ".framer-JwnsL .framer-18ixzj0 { flex: 1 0 0px; height: 1px; overflow: visible; position: relative; width: 100%; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-JwnsL.framer-ra4w44, .framer-JwnsL .framer-m3zxw4 { gap: 0px; } .framer-JwnsL.framer-ra4w44 > *, .framer-JwnsL .framer-m3zxw4 > * { margin: 0px; margin-bottom: calc(0px / 2); margin-top: calc(0px / 2); } .framer-JwnsL.framer-ra4w44 > :first-child, .framer-JwnsL .framer-m3zxw4 > :first-child { margin-top: 0px; } .framer-JwnsL.framer-ra4w44 > :last-child, .framer-JwnsL .framer-m3zxw4 > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 750
 * @framerIntrinsicWidth 600
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerVariables {"n75ENNN63":"image"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerkMvIH_OMU: React.ComponentType<Props> = withCSS(Component, css, "framer-JwnsL") as typeof Component;
export default FramerkMvIH_OMU;

FramerkMvIH_OMU.displayName = "Scroll Image Copy";

FramerkMvIH_OMU.defaultProps = {height: 750, width: 600};

addPropertyControls(FramerkMvIH_OMU, {n75ENNN63: {__defaultAssetReference: "data:framer/asset-reference,OWmqyHqUM5E6Uu1XmGANwfVq9Uc.jpg?originalFilename=DTS_Shadow+Tech_by+Fanette+Guilloud_16.jpg&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerkMvIH_OMU, [])